<template>
  <div class="page-container home">
    <div class="page-header">
      <ace-header show-action>
        <img src="../assets/logo.png" alt="logo" class="logo" />
        <span>{{ $t('system.app.title') }}</span>
      </ace-header>
    </div>
    <div class="page-body">
      <div class="home-video">
        <canvas id="video" class="w-full h-full bg-transparent"></canvas>
      </div>
      <div class="home-action">
        <div class="home-action-logo">
          <img class="max-w-full" src="../assets/dmen_logo.png" alt="dmen-logo" />
        </div>
        <router-link to="/products" tag="div" class="home-action-link">
          <div class="mx-auto custom-button custom-button-large">
            {{ $t('system.app.chargeNow') }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        player: null,
      };
    },
    mounted() {
      this.player = new window.JSMpeg.Player('/home_bg.ts', {
        canvas: document.getElementById('video'),
        decodeFirstFrame: true,
        disableWebAssembly: false,
        throttled: false, //这里设置为false，不然不触发onSourceCompleted事件
        chunkSize: 4 * 1024 * 1024,
        disableGl: false,
        audio: false,
        autoplay: true,
        loop: true,
        poster: '/home_bg.jpg',
        onSourceCompleted: () => {
          const video = document.getElementById('video');
          video.style.opacity = '1';
        },
      });
      this.player.play();
    },
    beforeDestroy() {
      this.player.destroy();
    },
  };
</script>

<style lang="less">
  .home {
    position: relative;
    height: 100vh;
    overflow: hidden;
    &-video {
      width: 100%;
      height: 100%;
      background-image: url(/home_bg.jpg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      #video {
        opacity: 0;
      }
    }
    &-action {
      position: absolute;
      top: 30%;
      width: 100%;
      text-align: center;
      &-logo {
        margin: 0 auto;
        width: 250px;
        height: 122px;
      }
      &-link {
        margin-top: 50px;
      }
    }
  }
</style>
